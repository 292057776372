<template>
    <gusa-container container-class="pt-0">
        <div style="display: flex; flex-direction: row">
            <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">DEVICE UPGRADE</div>
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <div>
            <v-row align="start">
                <v-col cols="12" sm="6">
                    <base-info-card
                        title="Contact Information"
                        color="primary"
                        subtitle="Who to contact for more information regarding this upgrade request if necessary."
                        body-space="0"
                    />
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6">
                    <base-info-card
                        title="Shipping Address"
                        subtitle="Address that the device will be shipped back to once the upgrades have been completed. Please note that this cannot be a PO box."
                        color="primary"
                        body-space="0"
                    />
                </v-col>
            </v-row>
            <v-row align="start">
                <v-col cols="12" sm="6">
                    <contact-form
                        ref="contactForm"
                        dense
                        :user-stored-contact="$attrs['user-stored-contact']"
                        @contactInput="$emit('contactInput', $event)"
                        @form-is-valid="contactFormIsValid = $event"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <base-info-card
                        v-if="!$vuetify.breakpoint.smAndUp"
                        title="Shipping Address"
                        subtitle="Address that the device will be shipped back to once the repairs have been completed. Please note that this cannot be a PO box."
                        color="primary"
                        body-space="0"
                    />
                    <address-form
                        ref="addressForm"
                        dense
                        :user-stored-address="$attrs['user-stored-address']"
                        :apo="$attrs.apo"
                        :cities="$attrs.cities"
                        :countries="$attrs.countries"
                        :state-provinces="$attrs['state-provinces']"
                        :states-loading="$attrs['states-loading']"
                        :cities-loading="$attrs['cities-loading']"
                        @stateUpdated="$emit('stateUpdated', $event)"
                        @countryUpdated="$emit('countryUpdated', $event)"
                        @addressInput="$emit('addressInput', $event)"
                        @form-is-valid="addressFormIsValid = $event"
                    />
                </v-col>
            </v-row>
            <div>
                <div
                    class="fields-missing justify-end"
                    :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'"
                >
                    <v-alert
                        v-if="showErrors"
                        block
                        type="error"
                        :class="!$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-2'"
                    >
                        Error Validating Information
                    </v-alert>
                    <v-card v-else flat height="72" :class="!$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-0'">
                    </v-card>
                </div>
            </div>
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <div class="btns-container" style="justify-content: space-between; margin-top: 20px; padding: 0px 20px">
            <v-btn color="primary" elevation="0" outlined @click="$emit('back')">Back</v-btn>
            <div class="right-side-btns" style="float: right">
                <v-btn color="primary" elevation="0" :disabled="!formValid" @click="$emit('continue')">Next</v-btn>
            </div>
        </div>
    </gusa-container>
</template>

<script>
import GusaContainer from '@/layouts/home/container.vue'
import AddressForm from '../../create_rma_stepper/forms/address_form.vue'
import ContactForm from '../../create_rma_stepper/forms/contact_form.vue'

export default {
    name: 'ContactInfo',
    components: {
        AddressForm,
        ContactForm,
        GusaContainer
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            contactFormIsValid: false,
            addressFormIsValid: false,
            showErrors: false
        }
    },
    computed: {
        formValid() {
            return !!(this.contactFormIsValid && this.addressFormIsValid)
        }
    },
    methods: {
        resetForm() {
            this.$refs.contactForm.resetForm()
            this.$refs.addressForm.resetForm()
        },
        validateOnContinue() {
            this.$refs.contactForm.$children[0].validate()
            this.$refs.addressForm.$children[0].validate()
            // TODO also show red outline on phone if invalid
            if (!this.formValid) this.showErrors = true
            const self = this
            setTimeout(() => {
                self.showErrors = false
            }, 4000)
        }
    }
}
</script>
