<template>
    <div>
        <v-stepper v-model="currentStep" dark style="background-color: transparent" elevation="0" class="elevation-0">
            <gusa-container inner-style="padding: 0px;">
                <v-stepper-header class="elevation-0">
                    <template v-for="(step, i) in steps">
                        <v-stepper-step
                            :key="step.title"
                            style="color: white"
                            :editable="isDev"
                            :complete="currentStep > i + 1"
                            :step="i + 1"
                        >
                            {{ step.title }}
                        </v-stepper-step>

                        <v-divider v-if="i !== steps.length - 1" :key="i"></v-divider>
                    </template>
                </v-stepper-header>
            </gusa-container>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <enter-serial
                        :quote-serial="!checkObj(quoteData) ? unitData.serial : ``"
                        :upgrade-items="!checkObj(quoteData) ? upgradeDetails : []"
                        @continue="continueFrom('serial')"
                        @problemInput="Object.assign(problemData, $event)"
                        @selectedUpgrades="selectUpgrades($event)"
                        @serial="storeSerial($event)"
                        @model="storeModel($event)"
                        @unitInput="unitData = $event"
                    ></enter-serial>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <problem-description
                        :quote-desc="!checkObj(quoteData) ? problemData.initialNotes : ``"
                        @continue="continueFrom('problem')"
                        @deviceInput="problemDesc"
                        @back="toPrevious('problem')"
                    ></problem-description>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <contact-info
                        :countries="countries"
                        :state-provinces="stateProvinces"
                        :cities="cities"
                        :apo="isApo"
                        :user-stored-address="userStoredAddress"
                        :user-stored-contact="userStoredContact"
                        :states-loading="statesLoading"
                        :cities-loading="citiesLoading"
                        @stateUpdated="getCities"
                        @countryUpdated="getStates"
                        @contactInput="Object.assign(contactData, $event)"
                        @addressInput="Object.assign(addressData, $event)"
                        @continue="continueFrom('contact')"
                        @back="toPrevious('contact')"
                    ></contact-info>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <packaging
                        ref="packing"
                        :quote-packing-data="!checkObj(quoteData) ? packingData : {}"
                        :address="addressData"
                        :apo="isApo"
                        :international="international"
                        :canada="canada"
                        @packingInput="Object.assign(packingData, $event)"
                        @continue="continueFrom('packing')"
                        @back="toPrevious('packing')"
                    ></packaging>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <confirmation
                        ref="confirm"
                        :problem-data="problemData"
                        :packing-data="packingData"
                        :contact-data="contactData"
                        :address-data="addressData"
                        :unit-data="unitData"
                        :override-data="overrideData"
                        :attachments-data="attachmentsData"
                        :submission-error="submissionError"
                        :posting-rma="postingRma"
                        :upgrade-options="upgradeDetails"
                        :upgrade-total="upgradeTotal"
                        :user-has-override="false"
                        @editPage="editStep"
                        @proceedPayment="proceedPayment($event)"
                        @continue="continueFrom('confirm')"
                        @back="toPrevious('confirm')"
                    >
                        <template
                            v-slot:buttons="{
                                formValid,
                                paymentMethod,
                                poNumber,
                                poUpload,
                                validate,
                                unableToSubmitPo,
                                releaseNumber
                            }"
                        >
                            <v-btn
                                color="primary"
                                width="175px"
                                large
                                outlined
                                :disabled="!formValid || !paymentMethod"
                                :loading="postingRma"
                                elevation="0"
                                @click="
                                    proceedPayment({
                                        paymentMethod: paymentMethod,
                                        save: false
                                    })
                                "
                            >
                                Save Quote
                            </v-btn>
                            &nbsp;
                            <paypal-button
                                v-if="paymentMethod === 'PayPal'"
                                :valid="!!formValid && !!paymentMethod"
                                :create="
                                    () =>
                                        proceedPayment({
                                            paymentMethod: paymentMethod,
                                            save: true
                                        })
                                "
                                :approve="processRma"
                                :validate="validate"
                            />
                            <v-btn
                                v-if="paymentMethod === 'PO'"
                                color="primary"
                                width="175px"
                                :disabled="!formValid || !paymentMethod || unableToSubmitPo"
                                large
                                outlined
                                :loading="postingRma"
                                elevation="0"
                                @click="
                                    proceedPayment({
                                        paymentMethod: paymentMethod,
                                        customerPO: poNumber,
                                        customerPOFileId: poUpload,
                                        releaseNumber: releaseNumber,
                                        save: true
                                    })
                                "
                                >Submit</v-btn
                            >
                        </template>
                    </confirmation>
                </v-stepper-content>

                <v-stepper-content step="6">
                    <print
                        :key="newRmaResponse.rmaNumber"
                        :user-input="{
                            problemData,
                            packingData,
                            contactData,
                            addressData,
                            unitData,
                            attachmentsData
                        }"
                        :international="international"
                        :create-rma-response="newRmaResponse"
                        @downloadLabel="downloadLabel"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-overlay :value="overlayProcessing" opacity="0.85" class="overflow:hidden;">
            <div class="text-center">
                <v-progress-circular class="ma-3" indeterminate large color="secondary" />
                <br />
                <span> Processing... </span>
            </div>
        </v-overlay>
    </div>
</template>

<script>
import PaypalButton from '@/components/paypal_button/paypal_button.vue'
import EnterSerial from '../../components/upgrade_device_stepper/steps/enter_serial.vue'
import ProblemDescription from '../../components/upgrade_device_stepper/steps/problem_description.vue'
import ContactInfo from '../../components/upgrade_device_stepper/steps/contact_info.vue'
import Packaging from '../../components/upgrade_device_stepper/steps/packaging.vue'
import Confirmation from '../../components/upgrade_device_stepper/steps/confirmation.vue'
import Print from '../../components/upgrade_device_stepper/steps/print.vue'
import GusaContainer from '@/layouts/home/container.vue'
import { createOowRma, cancelOowRma, processOowRma } from '@/api'
import { trimAll } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from 'lodash'
import { round } from '@/utils/truncate'


export default {
    name: 'UpgradeDevice',
    components: {
        EnterSerial,
        ProblemDescription,
        ContactInfo,
        Packaging,
        Confirmation,
        Print,
        GusaContainer,
        PaypalButton
    },
    props: {
        serial: { type: String, default: `` },
        toPage: { type: Number, required: false, default: 1 },
        quoteData: {
            type: Object,
            required: false,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            currentStep: this.toPage,
            upgradeDetails: [],
            overlayProcessing: false,
            statesLoading: false,
            citiesLoading: false,
            submissionError: ``,
            // serial: '',
            newRmaResponse: {
                rmaNumber: ``
            },
            problemData: {
                primaryFault: ``,
                problem: ``,
                additionalProblems: null,
                initialNotes: ``,
                permissions: {
                    reimage: false,
                    biosFirmware: false,
                    userId: ``,
                    userPassword: ``,
                    biosPassword: ``
                },
                acc: {
                    acPower: false,
                    storage: false,
                    hardCase: false,
                    battery: false
                },
                custReference: ``,
                assetTag: ``,
                problemPrice: ''
            },
            packingData: {
                havePackaging: false,
                requestBox: false
            },
            contactData: {
                firstName: ``,
                lastName: ``,
                email: ``,
                phone: ``,
                company: ``,
                phoneCountryCode: ``
            },
            addressData: {
                city: ``,
                country: ``,
                line1: ``,
                line2: ``,
                postalCode: ``,
                state: ``
            },
            paymentData: {},
            paymentUrl: '',
            paymentSuccess: false,
            rmaNumber: '',
            unitData: {},
            attachmentsData: [],
            overrideData: {},
            postingRma: false,
            upgradeTotal: 0
        }
    },
    computed: {
        steps() {
            const steps = [
                { title: `Enter Serial`, id: `serial` },
                { title: `Problem Description`, id: `problem` },
                { title: `Contact Info`, id: `contact` },
                { title: `Packaging & Shipping`, id: `packing` },
                { title: `Confirm`, id: `confirm` },
                { title: `Print`, id: `print` }
            ]

            return steps.map((el, i) => {
                el.step = i + 1
                return el
            })
        },
        international() {
            return this.addressData.country && this.addressData.country !== `US`
        },
        canada() {
            return this.addressData.country && this.addressData.country === 'CA'
        },
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },

        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.addressData.country,
                stateCode: this.addressData.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            })
        },
        userStoredAddress() {
            if (this.quoteData && this.quoteData.shipping && this.quoteData.shipping.address) {
                return {
                    city: this.quoteData.shipping.address.city,
                    country: this.quoteData.shipping.address.country,
                    line1: this.quoteData.shipping.address.line1,
                    line2: this.quoteData.shipping.address.line2,
                    postalCode: this.quoteData.shipping.address.postalCode,
                    state: this.quoteData.shipping.address.state
                }
            } else if (this.$auth.user.address && this.$auth.user.address.shipping) {
                return {
                    city: this.$auth.user.address.shipping.city,
                    country: this.$auth.user.address.shipping.country,
                    line1: this.$auth.user.address.shipping.line1,
                    line2: this.$auth.user.address.shipping.line2,
                    postalCode: this.$auth.user.address.shipping.postalCode,
                    state: this.$auth.user.address.shipping.state
                }
            } else return {}
        },
        userStoredContact() {
            if (this.quoteData && this.quoteData.shipping && this.quoteData.shipping.contact) {
                return {
                    company: this.quoteData.shipping.contact.companyName,
                    email: this.quoteData.shipping.contact.emailAddress,
                    firstName: this.quoteData.shipping.contact.firstName,
                    lastName: this.quoteData.shipping.contact.lastName,
                    phone: this.quoteData.shipping.contact.phoneNumber,
                    phoneCountryCode: this.quoteData.shipping.contact.phoneCountryCode
                }
            } else if (this.$auth.user) {
                return {
                    company: this.$auth.user.company,
                    email: this.$auth.user.email,
                    firstName: this.$auth.user.firstName,
                    lastName: this.$auth.user.lastName,
                    phone: this.$auth.user.phone,
                    phoneCountryCode: this.$auth.user.phoneCountryCode
                }
            } else return {}
        },
        isApo() {
            const stateObject = this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            }).find((e) => e.stateCode === this.addressData.state) || {
                apo: false
            }
            return !!stateObject.apo
        },
        isDev() {
            return process.env.NODE_ENV === `development`
        },
        payload() {
            const address = {
                line1: this.addressData.line1,
                line2: this.addressData.line2,
                city: this.addressData.city,
                state: this.addressData.state,
                country: this.addressData.country,
                postalCode: this.addressData.postalCode
            }
            const contact = {
                firstName: this.contactData.firstName,
                lastName: this.contactData.lastName,
                email: this.contactData.email,
                phone: this.contactData.phone,
                company: this.contactData.company,
                position: this.contactData.position
            }

            const packaging = {
                havePackaging: this.packingData.havePackaging,
                forgoShipping: false,
                requestBox: this.packingData.requestBox
            }

            const problemInput = {
                rmaType: `UPGRADE`,
                primaryFault: this.problemData.primaryFault,
                problem: this.problemData.problem,
                initialNotes: this.problemData.initialNotes,
                additionalProblems: this.problemData.additionalProblems,
                permissions: this.problemData.permissions,
                acc: this.problemData.acc,
                customerReference: this.problemData.custReference,
                assetTag: this.problemData.assetTag
            }

            return {
                serial: this.unitData.serial,
                rmaInput: {
                    ...problemInput,
                    attachments: this.attachmentsData
                },
                address,
                contact,
                packaging,
                paymentData: this.paymentData
            }
        }
    },
    watch: {
        currentStep: `scrollToTop`,
        quoteData: {
            immediate: true,
            handler() {
                console.log(this.quoteData)
                const quoteData = cloneDeep(this.quoteData)
                const country = quoteData.shipping.address.country
                const state = quoteData.shipping.address.state
                const rmaInput = quoteData.externalResource.rmaInput
                this.shipping = quoteData.shipping
                this.addressData = {
                    line1: this.quoteData.shipping.address.line1,
                    line2: this.quoteData.shipping.address.line2,
                    city: this.quoteData.shipping.address.city,
                    state: state,
                    country: country,
                    postalCode: this.quoteData.shipping.address.postalCode
                }
                this.packingData = {
                    havePackaging: quoteData.externalResource.rmaInput.packaging.havePackaging,
                    requestBox: quoteData.externalResource.rmaInput.packaging.requestBox
                }
                this.contactData = {
                    firstName: quoteData.shipping.contact.firstName,
                    lastName: quoteData.shipping.contact.lastName,
                    email: quoteData.shipping.contact.emailAddress,
                    phone: quoteData.shipping.contact.phone,
                    company: quoteData.shipping.contact.companyName
                }
                this.problemData = {
                    primaryFault: rmaInput.primaryFault,
                    problem: rmaInput.problem,
                    additionalProblems: rmaInput.additionalProblems,
                    problemPrice: round(quoteData.total),
                    initialNotes: rmaInput.initialNotes,
                    permissions: {
                        reimage: !!rmaInput.permissions.reimage,
                        biosFirmware: !!rmaInput.permissions.biosFirmware,
                        userId: rmaInput.biosFirmware || '',
                        userPassword: ``,
                        biosPassword: ``
                    },
                    acc: {
                        acPower: !!rmaInput.acc.acPower,
                        storage: !!rmaInput.acc.storage,
                        hardCase: !!rmaInput.acc.hardCase,
                        battery: !!rmaInput.acc.battery
                    },
                    custReference: rmaInput.custReference,
                    assetTag: rmaInput.assetTag
                }
                this.unitData = {
                    serial: quoteData.externalResource.serial,
                    model: ''
                }
                this.upgradeDetails = quoteData.items
            }
        }
    },
    methods: {
        checkObj(obj) {
            return isEmpty(obj)
        },
        paypalToken(url) {
            const params = new URLSearchParams(url.split('?')[1])
            if (!params.get('token')) {
                throw new Error('No PayPal token found')
            }
            return params.get('token')
        },
        async rmaCanceled() {
            await cancelOowRma({ rmaNumber: this.rmaNumber })
                .then(({ data }) => {
                    this.$router.push(`/canceled`)
                })
                .catch((e) => {
                    console.error(`API Error Cancelling RMA:` + e.data.message)
                })
        },
        async proceedPayment(event) {
            const po = event.releaseNumber ? `${event.customerPO}-${event.releaseNumber}` : event.customerPO
            event.customerPO = po
            if (event.releaseNumber) delete event.releaseNumber
            this.paymentData = event
            return await createOowRma({
                payload: trimAll(this.payload)
            })
                .then(({ data }) => {
                    this.paymentUrl = data.paymentUrl
                    this.rmaNumber = data.rmaNumber
                    this.paymentSuccess = true
                    if (data.quoteId) {
                        this.savedQuoteId = data.quoteId
                    }
                    return this.paymentData.paymentMethod === 'PayPal' ? this.paypalToken(this.paymentUrl) : null
                })
                .catch((e) => {
                    this.paymentSuccess = false
                    this.submissionError = `Error Creating RMA: ${e.data.message}`
                    console.error(`API Error Creating RMA:` + e.data.message)
                    throw e
                })
                .finally(() => {
                    this.postingRma = false
                    if (this.paymentData.save) {
                        // not save, but pay
                        if (this.paymentSuccess === true) {
                            if (this.paymentData.paymentMethod === 'PO') {
                                this.processRma()
                            }
                        } else if (this.paymentSuccess === false) {
                            console.log('Error')
                        }
                    } else {
                        // save quote
                        // router.push in the method below
                        if (this.paymentSuccess === true) this.processSaveQuote()
                    }
                })
        },
        async processSaveQuote() {
            const payload = trimAll(this.payload)
            this.$router.push({
                name: 'upgrade-confirm-quote',
                params: {
                    savedQuote: {
                        quoteId: this.savedQuoteId,
                        serial: payload.serial,
                        primaryFault: payload.rmaInput.primaryFault,
                        additionalProblems: payload.rmaInput.additionalProblems,
                        problem: payload.rmaInput.problem,
                        address: payload.address,
                        contact: payload.contact,
                        quoteFee: this.problemData.problemPrice
                        // packaging: payload.packaging
                    }
                }
            })
        },
        processRma() {
            this.overlayProcessing = true
            processOowRma({
                rmaNumber: this.rmaNumber,
                serial: this.unitData.serial
            })
                .then(({ data }) => {
                    this.newRmaResponse = data
                    this.currentStep = this.steps.length
                })
                .catch((e) => {
                    if (e.data.rmaNumber && e.data.rmaNumber.length > 0) {
                        this.newRmaResponse = { ...e.data, shippingError: true }
                        this.currentStep = this.steps.length
                    } else {
                        if (e.data && e.data.message && (`` + e.data.message).toLowerCase().includes(`shipper`)) {
                            this.submissionError = `Error validating address, please verify your information and try again.`
                        } else this.submissionError = `Error Creating RMA: ${e.data.message}`
                    }
                    console.error(`API Error Creating RMA:` + e.data.message)
                })
                .finally(() => {
                    this.overlayProcessing = false
                })
        },
        selectUpgrades(upgrades) {
            this.upgradeDetails = upgrades
            const prices = []
            upgrades.forEach((element) => {
                const price = element.price
                prices.push(price)
            })
            const sum = round(prices.reduce((a, b) => a + b, 0))
            this.upgradeTotal = sum
            this.problemData.problemPrice = sum
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0
            })
        },
        storeSerial(serial) {
            this.unitData.serial = serial
        },
        storeModel(model) {
            this.unitData.model = model
        },
        continueFrom(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step + 1
        },
        toPrevious(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step - 1
        },
        downloadLabel(url) {
            window.open(url)
        },
        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        },
        async getStates(countryCode) {
            this.statesLoading = true
            try {
                await this.$store.dispatch(`location/cacheStates`, {
                    countryCode
                })
                this.statesLoading = false
            } catch {
                this.statesLoading = false
            }
        },
        editStep(editStep) {
            const s = this.steps.find((el) => el.id === editStep)
            this.currentStep = s.step
        },
        problemDesc(event) {
            this.problemData.initialNotes = event.problemDescription
            if (event.userId && event.userPassword) {
                this.problemData.permissions.userId = event.userId
                this.problemData.permissions.userPassword = event.userPassword
            }
        }
    }
}
</script>
