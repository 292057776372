var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._g(_vm._b({attrs:{"flat":"","color":"transparent"}},'v-card',_vm.$attrs,false),_vm.$listeners),[_c('v-text-field',_vm._g(_vm._b({staticClass:"serial-validator pr-4",attrs:{"data-cy":"input-serial-field","label":"Input Serial #","outlined":""},on:{"input":_vm.verifySerial},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.callingApi)?_c('v-card',{attrs:{"flat":"","width":"24","height":"24","color":"transparent"}},[_c('v-progress-circular',{attrs:{"width":"2","size":"20","color":"secondary","indeterminate":""}})],1):_c('v-card',{attrs:{"width":"24","height":"24","color":"transparent","flat":""}},[(_vm.serialNotFound)?_c('v-card',{attrs:{"flat":"","data-cy":"serial-not-found-icon","color":"transparent","width":"24","height":"24"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.mdiAlertCircleOutline)+" ")])]}}],null,false,569257383)},[_c('span',[_vm._v("Serial Not Found")])])],1):(!_vm.serialIsValid && _vm.serialValue && _vm.serialValue.length >= 10)?_c('v-card',{attrs:{"data-cy":"serial-not-valid-icon","flat":"","color":"transparent","width":"24","height":"24"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.mdiCheckboxMarkedCircleOutline)+" ")])]}}])},[_c('span',[_vm._v("Serial Found")])])],1):(_vm.serialIsValid)?_c('v-card',{attrs:{"data-cy":"serial-valid-icon","flat":"","color":"transparent","width":"24","height":"24"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.mdiCheckboxMarkedCircleOutline)+" ")])]}}])},[_c('span',[_vm._v("Serial Found")])])],1):_c('v-card',{attrs:{"data-cy":"serial-not-input-icon","flat":"","color":"transparent","width":"24","height":"24"}})],1)]},proxy:true}]),model:{value:(_vm.serialValue),callback:function ($$v) {_vm.serialValue=$$v},expression:"serialValue"}},'v-text-field',_vm.$attrs,false),_vm.$listeners)),(_vm.serialIsValid)?_c('base-text-field',{staticStyle:{"margin-bottom":"30px"},attrs:{"value":_vm.model,"outlined":"","label":"Model","hide-details":"","readonly":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }