<template>
    <v-row dense justify="center">
        <v-col xl="5" lg="9" md="7" cols="12">
            <gusa-container container-class="pt-0">
                <div style="display: flex; flex-direction: row">
                    <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">DEVICE UPGRADE</div>
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div id="upgrade-card-left">
                        <v-card elevation="0" dark color="transparent">
                            <v-toolbar flat color="transparent" dark>
                                <h1 style="font-size: 36px; letter-spacing: -0.08rem">Enter Serial</h1>
                            </v-toolbar>
                            <p style="padding: 4px 16px; margin-top: -10px"
                                >Please enter the product serial number to begin the upgrade process.</p
                            >
                            <v-col cols="12">
                                <validate-warranty
                                    max-width="250"
                                    :outside-serial="quoteSerial"
                                    @serialData=";(unitData = $event), $emit('unitInput', $event)"
                                    @isValid="serialIsValid = $event"
                                    @serialInput="getSerialData"
                                />
                                <!-- <a
                                    style="
                                        padding: 4px 16px;
                                        margin-top: -10px;
                                        font-size: 14px;
                                        font-weight: 500;
                                    "
                                    >How do I find my product serial number?</a
                                > -->
                            </v-col>
                        </v-card>
                    </div>
                    <v-divider vertical style="border-color: rgba(33, 33, 33, 1) !important" />
                    <div id="upgrade-card-right" style="width: 100%">
                        <v-card v-if="isEOS">
                            <v-card-text>
                                <h1
                                    style="
                                        font-size: 15px;
                                        letter-spacing: -0.01rem;
                                        text-transform: uppercase;
                                        margin-bottom: 10px;
                                    "
                                    >Your unit is
                                    <br />
                                    <span style="color: #db5120"> End of Service </span></h1
                                >
                            </v-card-text>
                        </v-card>
                        <v-card v-if="!isEOS" elevation="0" dark color="transparent">
                            <div id="upgrade-option">
                                <h1 style="font-size: 24px; letter-spacing: -0.01rem; padding: 4px 16px"
                                    >Upgrade Options:</h1
                                >
                                <v-data-table
                                    style="background-color: transparent"
                                    dark
                                    :headers="optionHeaders"
                                    :loading="$apollo.loading"
                                    disable-sort
                                    :no-data-text="noItemsMessage"
                                    :items="upgradeOptions"
                                >
                                    <template v-slot:item.add="{ item }">
                                        <v-btn
                                            color="primary"
                                            elevation="0"
                                            small
                                            :disabled="disableBtn(item)"
                                            @click="addUpgrade(item)"
                                            >Add</v-btn
                                        >
                                    </template>
                                </v-data-table>
                            </div>
                        </v-card>
                    </div>
                </div>
                <v-divider style="margin-bottom: 0.5%; margin-top: 2%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    style="margin-left: 15px; margin-right: 15px; margin-top: 25px; color: #db5120"
                                    dark
                                    elevation="0"
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Repair Disclaimer
                                </v-btn>
                            </template>
                            <span style="width: 100px"
                                >Upgrade of your Getac Product is considered an out of warranty service and will require
                                a prepayment to authorize upgrade. <br />
                                You will have the option to generate a quote for approval or pay upfront through PayPal.
                                <br />
                                Should you need to upgrade your device with 2 or more upgrade options, please call Getac
                                support for a quote.</span
                            >
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    style="margin-left: 15px; margin-right: 15px; margin-top: 25px; color: #db5120"
                                    dark
                                    elevation="0"
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    EOL Disclaimer
                                </v-btn>
                            </template>
                            <span style="width: 100px"
                                >The EOL (End Of Life) units require additional time. Getac cannot guarantee a timely
                                repair.</span
                            >
                        </v-tooltip>
                    </div>
                </div>
            </gusa-container>
        </v-col>
        <v-col lg="3" md="5" cols="12">
            <gusa-container container-class="pt-0">
                <div>
                    <h1 style="font-size: 36px; letter-spacing: -0.08rem"> Selected Options </h1>
                    <p>Confirm or remove selected upgrade options.</p>
                    <v-data-table
                        v-model="selectedToRemove"
                        style="background-color: transparent"
                        dark
                        item-key="name"
                        :headers="headers"
                        show-select
                        hide-default-footer
                        disable-sort
                        :items="selectedUpgrades"
                    >
                        <template v-slot:item.price="{ value }"> ${{ value }} </template>
                        <template v-slot:body.append>
                            <tr>
                                <th colspan="2"><h3> Total Amount </h3></th>
                                <th class="text-right"
                                    ><h3>${{ discountAmount }}</h3></th
                                >
                            </tr>
                            <tr>
                                <th colspan="2"><h3>Discount Amount </h3></th>
                                <th class="text-right"
                                    ><h3>${{ discountPriceComputed }}</h3></th
                                >
                            </tr>
                            <tr>
                                <th colspan="2"><h3>Total after Discount </h3></th>
                                <th class="text-right"
                                    ><h3>${{ discountAfterTotal }}</h3></th
                                >
                            </tr>
                        </template>
                    </v-data-table>

                    <v-divider class="mt-5" style="margin-bottom: 1%; border-color: gray" />
                    <div
                        class="btns-container"
                        style="justify-content: space-between; margin-top: 20px; padding: 0px 20px"
                    >
                        <v-col cols="6">
                            <v-btn
                                color="primary"
                                class="mr-2"
                                elevation="0"
                                :disabled="!selectedUpgrades.length || !selectedToRemove.length"
                                @click="removeSelected"
                            >
                                Remove Selected
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                color="primary"
                                elevation="0"
                                :disabled="!selectedUpgrades.length"
                                @click="
                                    $emit('continue')
                                    emitInput()
                                "
                            >
                                Next
                            </v-btn>
                        </v-col>
                    </div>
                </div>
            </gusa-container>
        </v-col>
    </v-row>
</template>

<script>
import ValidateWarranty from '../serial_validation/index.vue'
import GusaContainer from '@/layouts/home/container.vue'
import gql from 'graphql-tag'

export default {
    name: 'EnterSerial',
    components: { ValidateWarranty, GusaContainer },
    props: {
        quoteSerial: {
            type: String,
            required: true
        },
        upgradeItems: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedToRemove: [],
            serial: '',
            model: '',
            pn: '',
            isEOS: false,
            optionHeaders: [
                {
                    text: 'Upgrade',
                    value: 'name',
                    align: 'left'
                },
                {
                    text: 'Price',
                    value: 'price',
                    align: 'left'
                },
                {
                    text: '',
                    value: 'add',
                    align: 'right'
                }
            ],
            headers: [
                {
                    text: 'Upgrade',
                    value: 'name',
                    align: 'left'
                },
                {
                    text: 'Price',
                    value: 'price',
                    align: 'right'
                }
            ],
            upgradeOptions: [],
            discountPrice: 0,
            noItemsMessage: 'Please input a serial number to see available upgrade options',
            selectedUpgrades: [],
            formInput: {
                permissions: {
                    acknowledgeHddBackup: false,
                    reimage: false,
                    biosFirmware: false
                },
                acc: {
                    acPower: false,
                    storage: false,
                    hardCase: false,
                    battery: false
                },
                primaryFault: 'UPGRADE'
            }
        }
    },

    computed: {
        discountAmount() {
            // Computed property in Vue. Essentially a value that contains it's own logic about how it gets to it's value. Changes based on the data
            // inside of this function changes. Do whatever logic to get the dollar amount and return it. Will be able to used anywhere in the HTML
            // to print out.
            let testDiscountAmount = 0
            for (const e of this.selectedUpgrades) {
                testDiscountAmount += e.price
            }
            return testDiscountAmount.toFixed(2)
        },
        discountPriceComputed() {
            let result = 0 // starting discount amount
            // create new variable to save a sorted copy of this.selectedUpgrades (sorted by the price, highest to lowest)
            // for each (item, index) in new sorted array
            //      if the upgrade option name is NOT OS reimage and the index > 0
            //          calculate the discount amount for that option and add to result
            const sortedDiscount = [...this.selectedUpgrades]
            sortedDiscount.sort((a, b) => {
                return b.price - a.price
            })
            sortedDiscount.forEach((item, index) => {
                if (item.name !== 'OS REIMAGE' && index > 0) {
                    result += item.price * 0.08
                }
            })

            return result.toFixed(2)
        },
        discountTotal() {
            // console.log(this.discountMethod().data)
            // This property needs to return a numerical value, not NaN or Native Code. Figure out how to access the discountMethod() value and apply it here.
            const discountTotalAmount = this.discountPrice.toFixed(2)
            return discountTotalAmount
        },
        discountAfterTotal() {
            return (this.discountAmount - this.discountPriceComputed).toFixed(2)
            // return (this.discountAmount - this.discountTotal).toFixed(2)
        }
    },

    watch: {
        upgradeItems: {
            immediate: true,
            handler(v) {
                const items = v.map((e) => ({
                    name: e.name,
                    price: e.price,
                    tip: null
                }))
                this.selectedUpgrades = items
            }
        }
    },

    methods: {
        removeSelected() {
            this.selectedUpgrades = this.selectedUpgrades.filter(
                (upgrade) => !this.selectedToRemove.some((selected) => selected.name === upgrade.name)
            )
            this.selectedToRemove = []
        },
        getSerialData(value) {
            this.model = value.model
            this.serial = value.serial
            this.pn = value.pn
            this.isEOS = value.isEOS

            if (this.pn && this.model) {
                this.$apollo
                    .query({
                        query: gql`
                            query UpgradeFormSelections($pn: String!, $model: String!) {
                                upgradeFormSelections(pn: $pn, model: $model) {
                                    name
                                    options {
                                        name
                                        tip
                                        price
                                    }
                                }
                            }
                        `,
                        variables: {
                            pn: this.pn,
                            model: this.model
                        }
                    })
                    .then((data) => {
                        this.upgradeOptions = data.data.upgradeFormSelections[0].options
                        if (this.upgradeOptions.length === 0) {
                            this.noItemsMessage =
                                'No available upgrade options for this serial. Please contact Getac for more information.'
                        }
                    })
                    .catch(() => {
                        this.noItemsMessage =
                            'No available upgrade options for this serial. Please contact Getac for more information.'
                    })
            }
        },
        addUpgrade(upgrade) {
            this.selectedUpgrades.push(upgrade)
        },
        emitInput() {
            this.$nextTick()
            if (this.selectedUpgrades.length > 1) {
                const upgradeNames = []
                this.selectedUpgrades.forEach((element) => {
                    const name = element.name
                    upgradeNames.push(name)
                })
                this.formInput.problem = this.selectedUpgrades[0].name
                upgradeNames.shift()
                this.formInput.additionalProblems = upgradeNames
            } else if (this.selectedUpgrades.length === 1) {
                this.formInput.problem = this.selectedUpgrades[0].name
            }
            this.$emit(`problemInput`, this.formInput)
            this.$emit(`selectedUpgrades`, this.selectedUpgrades)
            this.$emit(`serial`, this.serial)
            this.$emit(`model`, this.model)
        },
        disableBtn(upgrade) {
            const match = this.selectedUpgrades.find((element) => element.name === upgrade.name)
            if (match) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
