<template>
    <v-card v-bind="$attrs" flat color="transparent" v-on="$listeners">
        <v-text-field
            v-model="serialValue"
            data-cy="input-serial-field"
            label="Input Serial #"
            class="serial-validator pr-4"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            @input="verifySerial"
        >
            <template v-slot:append-outer>
                <v-card v-if="callingApi" flat width="24" height="24" color="transparent">
                    <v-progress-circular width="2" size="20" color="secondary" indeterminate></v-progress-circular>
                </v-card>
                <v-card v-else width="24" height="24" color="transparent" flat>
                    <v-card
                        v-if="serialNotFound"
                        flat
                        data-cy="serial-not-found-icon"
                        color="transparent"
                        width="24"
                        height="24"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="error" dark v-bind="attrs" v-on="on">
                                    {{ mdiAlertCircleOutline }}
                                </v-icon>
                            </template>
                            <span>Serial Not Found</span>
                        </v-tooltip>
                    </v-card>
                    <v-card
                        v-else-if="!serialIsValid && serialValue && serialValue.length >= 10"
                        data-cy="serial-not-valid-icon"
                        flat
                        color="transparent"
                        width="24"
                        height="24"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="green" dark v-bind="attrs" v-on="on">
                                    {{ mdiCheckboxMarkedCircleOutline }}
                                </v-icon>
                            </template>
                            <span>Serial Found</span>
                        </v-tooltip>
                    </v-card>
                    <v-card
                        v-else-if="serialIsValid"
                        data-cy="serial-valid-icon"
                        flat
                        color="transparent"
                        width="24"
                        height="24"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="green" dark v-bind="attrs" v-on="on">
                                    {{ mdiCheckboxMarkedCircleOutline }}
                                </v-icon>
                            </template>
                            <span>Serial Found</span>
                        </v-tooltip>
                    </v-card>
                    <v-card v-else data-cy="serial-not-input-icon" flat color="transparent" width="24" height="24" />
                </v-card>
            </template>
        </v-text-field>
        <base-text-field
            v-if="serialIsValid"
            :value="model"
            style="margin-bottom: 30px"
            outlined
            label="Model"
            hide-details
            readonly
        />
    </v-card>
</template>
<script>
import { verifySerialForRma } from '@/api'
import { mdiAlertCircleOutline, mdiCheckboxMarkedCircleOutline, mdiCancel } from '@mdi/js'
export default {
    name: `ValidateWarranty`,

    props: {
        outsideSerial: {
            type: String,
            default: ``
        }
    },

    data() {
        return {
            callingApi: false,
            mdiCheckboxMarkedCircleOutline,
            mdiAlertCircleOutline,
            mdiCancel,
            inputSerial: ``,
            serialValue: ``,
            serialData: {},
            model: ''
        }
    },

    computed: {
        serialNotFound() {
            return !!(
                this.serialValue &&
                !Object.keys(this.serialData).includes(`iw`) &&
                !Object.keys(this.serialData).includes(`rmaExists`)
            )
        },
        serialIsValid() {
            return !!(this.serialData.serial && !this.serialData.rmaExists) || !!this.serialData.allowOow
        }
    },

    mounted() {
        if (this.outsideSerial) {
            this.serialValue = this.outsideSerial
        }
        this.verifySerial(this.serialValue)
    },

    methods: {
        async verifySerial(serial) {
            this.serialData = {}
            if (serial && serial.length >= 9) {
                this.callingApi = true
                try {
                    const { data } = await verifySerialForRma({
                        serial: serial.toUpperCase().trim()
                    })

                    this.serialData = data.constructor.name === `Object` ? data : {}
                } catch (error) {
                    console.error(error)
                } finally {
                    this.callingApi = false
                }
            }
            this.$emit(`isValid`, this.serialIsValid)
            this.$emit(`serialData`, this.serialData)
            this.$emit(`serialInput`, this.serialData)
            this.model = this.serialData.model
        }
    }
}
</script>
<style lang="scss">
.serial-validator {
    input {
        text-transform: uppercase;
    }
}
</style>
